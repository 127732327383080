<template>
  <div class="storeOperation">
    <div class="box">
      <button
        :class="loadProduct.IsCollectedForShop ? 'savedStore' : 'addStore'"
        @click="addProductMyStore(productNumber)"
        :disabled="loadProduct.IsCollectedForShop ? true : false"
      >
        <span v-if="loadProduct.IsCollectedForShop">{{ $t.savedToStore }}</span>
        <span v-else>{{ $t.addToStore }}</span>
      </button>
      <div style="display: none">{{ productNumber }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StoreOperation",
  props: ["productNumber", "loadProduct"],
  data() {
    return {
      newProductNumber: this.productNumber,
    };
  },
  methods: {
    // 添加商品到店铺
    addProductMyStore(productNumber) {
      let param = {
        productNumber: productNumber,
      };
      this.$api.product
        .addProductByProductNumber(param)
        .then((res) => {
          const data = res.data;
          this.$emit("reload", data ? true : false);
          this.$commonMethod.customAlert(
            {
              title: this.$t.operateSuccessfully,
              message: res.message,
              cancelButtonText: this.$t.alert.cancelButtonText,
              confirmButtonText: this.$t.meta.goCheck,
            },
            () => {
              this.$commonEnv.commonAction(
                this.$commonEnv.actionType.routerPush,
                {
                  isParams: true,
                  path: "commodityManage",
                  name: "commodityManage",
                  query: [
                    {
                      name: "Offline",
                      value: true,
                    },
                  ],
                }
              );
            },
            () => {}
          );
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.storeOperation {
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  border-top: 1px solid #e5e5e5;
  position: fixed;
  bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  .box {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    .addStore {
      border: none;
      background-color: #ffffff;
      font-size: 16px;
      color: #43cd9a;
      text-align: center;
      border-radius: 50px;
      width: 48.5%;
      border: 1px solid #ebedf0;
      padding: 10px 0;
      margin-right: 20px;
      margin: 0 auto; //暂时居中
    }
    .savedStore {
      border: none;
      background-color: #ffffff;
      font-size: 16px;
      color: #cec5c5;
      text-align: center;
      border-radius: 50px;
      width: 48.5%;
      border: 1px solid #ebedf0;
      padding: 10px 0;
      margin-right: 20px;
      margin: 0 auto; //暂时居中
    }
    .sharpStore {
      width: 48.5%;
      //border: 1px solid #cec5c5;
      padding: 24px 0;
      text-align: center;
      color: #ffffff;
      background-image: url("~assets/img/home/icon_home_button.png");
      // background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
