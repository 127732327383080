<template>
  <div class="sku">
    <div class="topSku">
      <img
        class="productImg"
        :src="
          loadProduct.ProductPhoto && imgUrlFilter(loadProduct.ProductPhoto)
        "
      />
      <section class="titleInfo">
        <!-- <p class="sell">{{ $t.sell }}{{ loadProduct.Sales }}{{ $t.piece }}</p> -->
        <p class="sell">{{ loadProduct.ProductName }}</p>
        <p class="price">${{ loadProduct.CurrentPrice }}</p>
        <!-- <div class="specList">
          <span class="specTitle">{{ $t.pleaseSelect }}</span>
          <span
            class="specItem"
            v-for="item in loadProduct.ListClassModels"
            :key="item.PCModelId"
            >{{ item.ModelName }}</span
          >
        </div>-->
      </section>
    </div>
    <!-- 规格列表 -->
    <div class="skuList">
      <ul class="listBox">
        <li
          class="itemBox"
          v-for="item in loadProduct.ListClassModels"
          v-show="item.Checked"
          :key="item.PCModelId"
        >
          <p class="itemTitle">{{ item.ModelName }}</p>
          <section class="specBox">
            <div
              v-for="newItem in item.SonClassModels"
              v-show="newItem.Checked"
              :class="['specItem', 'specItemAction']"
              :key="newItem.PCModelId"
            >
              {{ newItem.ModelName }}
            </div>
          </section>
        </li>
      </ul>
    </div>
    <div class="sub" @click="sub">{{ $t.confirm }}</div>
  </div>
</template>
<script>
export default {
  name: "Sku",
  props: ["loadProduct"],
  data() {
    return {};
  },
  watch: {},
  mounted() {
    console.log(this.loadProduct);
  },
  methods: {
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    sub() {
      this.$emit("showPopup");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.sku {
  padding-bottom: 20px;
  .topSku {
    width: 88%;
    margin: 0 auto;
    margin-top: 22px;
    * {
      margin: 0;
      padding: 0;
    }
    @include publicFlex;
    .productImg {
      width: 90px;
      height: 90px;
      margin-right: 40px;
      object-fit: cover;
    }
    .titleInfo {
      flex: 1;
      -webkit-felx: 1;
      .price {
        font-size: 16px;
        color: #ff5852;
        font-weight: bold;
        margin-top: 10px;
      }
      .sell {
        font-size: 14px;
        // color: #a7a7a7;
      }
      .specList {
        margin-top: 20px;
        width: 100%;
        font-size: 20px;
        color: #000000;
        .specTitle {
          margin-right: 5px;
        }
        .specItem {
          margin-right: 5px;
        }
      }
    }
  }
  .skuList {
    width: 88%;
    margin: 0 auto;
    padding-bottom: 40px;
    min-height: 200px;
    * {
      margin: 0;
      padding: 0;
    }
    .listBox {
      width: 100%;
      list-style: none;
      @include divPublic;
      .itemBox {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 20px;
        margin-bottom: 12px;
        .itemTitle {
          font-size: 14px;
          color: #000000;
        }
        .specBox {
          width: 100%;
          @include publicFlex;
          //display: flex;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          .specItem {
            min-width: 20.9%;
            border-radius: 10px;
            background-color: #f6f6f6;
            color: #aeaeae;
            font-size: 12px;
            text-align: center;
            padding: 8px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            margin-right: 4.1%;
            margin-top: 10px;
            &:nth-child(1) {
              margin-left: 0;
              margin-right: 4.1%;
            }
          }
          .specItemAction {
            background-color: #48d58e;
            color: #ffffff;
          }
        }
      }
    }
  }
  .sub {
    width: 45%;
    margin: 0 auto;
    color: #ffffff;
    background-color: #48d58e;
    border-radius: 50px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
  }
}
</style>
