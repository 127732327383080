<template>
  <div class="detailsInfo">
    <!-- 价格 -->
    <section class="priceBox">
      <div class="priceBoxTitle">
        <div class="discount" v-if="loadProduct.IsCouponProduct">
          {{ $t.discount }}
        </div>
        <div class="name">
          {{ loadProduct.ProductName }}
        </div>
      </div>
      <div class="priceBoxCon">
        <span class="priceBoxUnit">$</span>
        <!-- <span class="priceBoxNum">{{ loadProduct.CurrentPrice }}</span> -->
        <span class="priceBoxNum">
          {{ Math.round(loadProduct.MinPrice * 100) / 100 }}
        </span>
      </div>
      <!-- <van-row type="flex" justify="space-between" class="carriageInfo">
        <van-col span="12" class="carriage">运费：$1.6</van-col>
        <van-col span="12" class="repertory">
          <img src="" />
        </van-col>
      </van-row>-->
    </section>
    <section class="productDesc">
      <h3 class="desc">{{ $t.productDescription }}</h3>
      <p class="descInfo">
        {{
          loadProduct.Descriptions == ""
            ? $t.noDetails && $t.noDetails
            : loadProduct.Descriptions
        }}
      </p>
    </section>
    <van-row
      type="flex"
      justify="space-between"
      align="center"
      class="productSize"
      @click="showPopup"
    >
      <van-col span="20" class="productSizeLeft">
        <ul
          class="productSizeList"
          v-for="item in loadProduct.ListClassModels"
          v-show="item.Checked"
          :key="item.PCModelId"
        >
          <li class="productSizeTitle">{{ item.ModelName }}</li>
          <li class="productSizeColorBox">
            <div
              class="productSizeColor"
              v-for="(newItem, index) in item.SonClassModels"
              v-show="newItem.Checked"
              :key="newItem.PCModelId"
            >
              {{ index === 0 ? "" : "/" }}{{ newItem.ModelName }}
            </div>
          </li>
        </ul>
      </van-col>
      <van-col span="4" class="productSizeGo">
        <img class="goIcon" src="~assets/img/details/icon_shop_right.png" />
      </van-col>
    </van-row>
    <!-- 供应商 -->
    <van-row
      type="flex"
      justify="space-between"
      align="center"
      class="provider"
    >
      <van-col :span="5" class="providerUserImg">
        <van-image
          class="providerUserIcon"
          :src="loadProduct.HeadPhoto && imgUrlFilter(loadProduct.HeadPhoto)"
        >
          <template v-slot:error>
            <img
              class="ImgIcon"
              src="~assets/img/userManage/user_logo@2x.png"
            />
          </template>
        </van-image>
      </van-col>
      <van-col :span="12" class="providerInfo">
        <h5 class="providerInfoTitle">{{ $t.supplier && $t.supplier }}</h5>
        <div class="providerInfoP">{{ loadProduct.CompanyName }}</div>
      </van-col>
      <van-col
        :span="7"
        class="providerSkip"
        @click="getDetailsInfo(loadProduct.CustomerId)"
      >
        <button class="providerSkipBt">{{ $t.in && $t.in }}</button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Row, Col, Image } from "vant";
Vue.use(Row).use(Col).use(Image);

export default {
  name: "DetailsInfo",
  props: ["loadProduct"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.loadProduct);
  },
  methods: {
    showPopup() {
      this.$emit("showPopup");
    },
    imgUrlFilter(value) {
      //console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value);
    },
    //跳转详情页
    getDetailsInfo(customerId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "drawableInfo",
        name: "DrawableInfo",
        query: [
          {
            name: "CustomerId",
            value: customerId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin publicBox {
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #ebedf0;
  //border-bottom: 1.125px solid #cec5c5;
  // box-sizing: border-box;
  // -webkit-box-sizing: border-box;
}
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.detailsInfo {
  width: 95%;
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  .priceBox {
    //min-height: 230px;
    min-height: 150px;
    padding: 0 20px;
    @include publicBox;
    .priceBoxTitle {
      margin: 0;
      padding: 0;
      margin-top: 20px;
      font-size: 16px;
      color: #000000;
      width: 100%;
      .discount {
        vertical-align: middle;
        display: inline-block;
        background: red;
        color: white;
        padding: 0px 2px;
        border-radius: 2px;
        font-size: 12px;
        margin-right: 5px;
      }
      .name {
        vertical-align: middle;
        display: inline-block;
      }
    }
    .priceBoxCon {
      margin-top: 50px;
      text-align: right;
      .priceBoxUnit {
        color: #ff5852;
        font-size: 18px;
        margin-right: 5px;
        font-weight: bold;
      }
      .priceBoxNum {
        color: #ff5852;
        font-size: 25px;
        font-weight: bold;
      }
    }
    .carriageInfo {
      font-size: 24px;
      .carriage {
        color: #a7a7a7;
      }
      .repertory {
        color: #25b9ff;
        text-align: right;
      }
    }
  }
  .productDesc {
    margin-top: 10px;
    padding: 0 20px;
    @include publicBox;
    .desc {
      margin: 0;
      padding: 0;
      margin-top: 20px;
      font-size: 17px;
      color: #000000;
    }
    .descInfo {
      margin: 0;
      padding: 0;
      font-size: 14px;
      margin-top: 20px;
      color: #666666;
      margin-bottom: 30px;
    }
  }
  .productSize {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @include publicBox;
    .productSizeLeft {
      .productSizeList:last-child {
        margin-bottom: 0 !important;
      }
      .productSizeList {
        width: 100%;
        list-style: none;
        margin-bottom: 0.3rem;
        padding: 0;
        @include puiblicFlex;
        font-size: 14px;
        .productSizeTitle {
          color: #43cd9a;
          width: 27%;
        }
        .productSizeColorBox {
          width: 70%;
          @include spanNowrap;
          @include puiblicFlex;
          .productSizeColor {
            color: #000000;
          }
        }
      }
      .productSizeListTop {
        margin-top: 16px;
      }
    }
    .productSizeGo {
      text-align: right;
      line-height: 20px;
      .goIcon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .provider {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @include publicBox;
    .providerUserImg {
      @include puiblicFlex;
      width: 20.83%;
      .providerUserIcon {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        object-fit: cover;
        .ImgIcon {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
        }
        ::v-deep .van-image__img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
        }
        ::v-deep .van-image__error {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
        }
      }
    }
    .providerInfo {
      widows: 50%;
      padding: 0 10px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      text-align: left;
      .providerInfoTitle {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #000000;
        font-weight: 500;
      }
      .providerInfoP {
        @include spanNowrap;
        margin: 0;
        padding: 0;
        widows: 100%;
        padding-top: 10px;
        font-size: 14px;
        color: #a7a7a7;
      }
    }
    .providerSkip {
      width: 29%;
      text-align: right;
      .providerSkipBt {
        border: none;
        margin: 0;
        padding: 0;
        border-radius: 50px;
        background-color: #43cd9a;
        width: 100%;
        color: #ffffff;
        font-size: 14px;
        line-height: 35px;
      }
    }
  }
}
</style>
